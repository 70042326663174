@import "../printcss/style.min";

//上書き用
html,body{
    width: 1240px;
}
.header-contact-wrap{
    padding-left: 380px;
}

.main-slider {
    .owl-nav {
        margin-top: 25px;

        & [class*='owl-'] {
            top: auto;
            //bottom:15px;
            bottom: -7px;
            border: none;
        }
    }
.owl-prev {
    left: calc(50% - 120px);
}

.owl-next {

    right: calc(50% - 120px);
}     
}

.index-main,
.page-main{
    margin-top: 0;
    padding-top: 110px;
    overflow-x: hidden;
}
      
.js_print{
    display: block;
}
header,
.fix-header{
    position: absolute;
}
.top-btn {
display: none !important;;    
}
.btn-return:before{
right: auto;    
}
body,p,h1,h2,h3,h4,table,span,div{
    //font-family: sans-serif !important;
}
.js_fadein,
.event-list-item,.bloglist,.workslist>div,.js_fadein-timeline>div{
    opacity: 1 !important;
    transform: matrix(1, 0, 0, 1, 0, 0) !important;
}

