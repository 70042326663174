  body {
    overflow-x: hidden;
    font-size: 16px;
    font-size: 1.6rem;
  }

  .google-link:hover,
  .txt-link:hover,
  .txt-tellink a:hover {
    text-decoration: none;
    color: #4892cc;
  }

  .l-max {
    margin: 0 auto;
    padding: 0;
  }

  .l-contents {
    width: 1120px;
    margin: 0 auto;
    padding: 0;
  }

  .l-outside {
    max-width: 1400px;
    min-width: 1120px;
    margin: 0 auto;
    padding: 0;
  }

  .l-inside {
    width: 960px;
    margin: 0 auto;
    padding: 0;
  }

  .l-1200 {
    width: 1200px;
    margin: 0 auto;
    padding: 0;
  }

  .l-1118 {
    max-width: 1118px;
    margin: 0 auto;
    padding: 0;
  }

  .l-inside1000 {
    width: 1000px;
    margin: 0 auto;
    padding: 0;
  }

  .l-inside800 {
    width: 800px;
    margin: 0 auto;
    padding: 0;
  }

  .l-map {
    padding-bottom: 55.55%;
  }

  .l-btn-index-service {
    display: none;
  }

  .l-btn {
    padding-top: 50px;
    padding-bottom: 20px;
  }

  .l-btn-root {
    margin: 70px 0 0;
    display: flex;
    flex-wrap: wrap;
  }

  .l-btn-caution {
    margin: 50px 0 70px;
  }

  .l-form-btn {
    display: flex;
    width: 700px;
    margin: 70px auto 0;
  }

  .l-form-btn button {
    width: 300px!important;
  }

  .l-btn--info {
    margin: 50px 0 -20px;
  }

  .l-btn-googlemap {
    margin: 30px 0;
  }

  .l-btn-googlemap a {
    margin: auto;
  }

  .l-header {
    position: relative;
    width: 1120px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .l-footer {
    display: flex;
    flex-wrap: wrap;
    width: 710px;
    margin: 0 auto;
  }

  .l-blog-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .l-bloglist-main {
    width: 820px;
    flex: 0 0 820px;
  }

  .l-bloglist-side {
    flex-shrink: 0;
    min-width: 260px;
    max-width: 260px;
  }

  .l-flex-md {
    display: flex;
  }

  .l-aligncenter-md {
    align-items: center;
  }

  .l-wrap-md {
    flex-wrap: wrap;
  }

  .l-gutter-md0 {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0;
    margin-right: 0;
  }

  .l-gutter-md0 [class^=l-col-],
  .l-gutter-md0 [class*=l-col-] {
    padding-left: 0;
    padding-right: 0;
  }

  .l-gutter-md5 {
    display: flex;
    flex-wrap: wrap;
    margin-left: -2.5px;
    margin-right: -2.5px;
  }

  .l-gutter-md5 [class^=l-col-],
  .l-gutter-md5 [class*=l-col-] {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }

  .l-gutter-md10 {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
  }

  .l-gutter-md10 [class^=l-col-],
  .l-gutter-md10 [class*=l-col-] {
    padding-left: 5px;
    padding-right: 5px;
  }

  .l-gutter-md16 {
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
    margin-right: -8px;
  }

  .l-gutter-md16 [class^=l-col-],
  .l-gutter-md16 [class*=l-col-] {
    padding-left: 8px;
    padding-right: 8px;
  }

  .l-gutter-md20 {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
  }

  .l-gutter-md20 [class^=l-col-],
  .l-gutter-md20 [class*=l-col-] {
    padding-left: 10px;
    padding-right: 10px;
  }

  .l-gutter-md40 {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-right: -20px;
  }

  .l-gutter-md40 [class^=l-col-],
  .l-gutter-md40 [class*=l-col-] {
    padding-left: 20px;
    padding-right: 20px;
  }

  .l-gutter-md50 {
    display: flex;
    flex-wrap: wrap;
    margin-left: -25px;
    margin-right: -25px;
  }

  .l-gutter-md50 [class^=l-col-],
  .l-gutter-md50 [class*=l-col-] {
    padding-left: 25px;
    padding-right: 25px;
  }

  .l-col-md1,
  .l-col-md2,
  .l-col-md5of1,
  .l-col-md7of1,
  .l-col-md7of3,
  .l-col-md3,
  .l-col-md4,
  .l-col-md5,
  .l-col-md6,
  .l-col-md7,
  .l-col-md8,
  .l-col-md9,
  .l-col-md10,
  .l-col-md11,
  .l-col-md12,
  .l-col-md-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
  }

  .l-col-md-auto {
    flex: 0 0 auto;
    max-width: 100%;
  }

  .l-col-md12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .l-col-md11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .l-col-md10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .l-col-md9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .l-col-md8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .l-col-md7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .l-col-md6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .l-col-md5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .l-col-md4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .l-col-md3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .l-col-md1of5 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .l-col-md2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .l-col-md1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  header {
    width: 100%;
    padding: 0;
    height: 110px;
  }

  .index-header header.alpha {
    color: #222;
  }

  .header-left {
    display: flex;
    align-items: center;
    flex: 0 0 700px;
    height: 65px;
  }

  .header-right {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
  }

  .l-logo-header {
    flex: 0 0 250px;
    margin-top: 13px;
    image-rendering: -webkit-optimize-contrast;
  }

  .logo-header {
    width: 250px;
    height: 50px;
  }

  .header-copy {
    font-size: 1.2rem;
    margin: -2px 0 0 20px;
  }

  .header-tel {
    font-family: Noto Serif JP,serif;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #403276;
    font-size: 3rem;
    line-height: 1.3;
    letter-spacing: .1em;
  }

  .header-tel .txt-tellink a {
    text-decoration: none;
    color: #403276;
  }

  .header-tel-title {
    font-size: 1.4rem;
    margin: 11px 10px 0 0;
    letter-spacing: 0;
  }

  .menu {
    flex: 0 0 100%;
  }

  .main-menu {
    display: flex;
    font-size: 1.5rem;
    position: relative;
  }

  .main-menu:after {
    content: "";
    position: absolute;
    right: 0;
    background: #dddfde;
    width: 1px;
    bottom: 12px;
    top: 10px;
    display: block;
  }

  .main-menu-list {
    font-size: 1.6rem;
    max-height: 80px;
    flex: 0 0 20%;
    text-align: center;
  }

  .main-menu-list>a {
    display: block;
    position: relative;
    padding: 13px 10px 17px;
    line-height: 16px;
  }

  .main-menu-list>a:before {
    content: "";
    position: absolute;
    left: 0;
    background: #dddfde;
    width: 1px;
    bottom: 12px;
    top: 10px;
    display: block;
  }

  .main-menu-list:before {
    display: none;
  }

  .main-menu-list:not(.nomega):after {
    transition: opacity .3s,bottom .3s;
    height: 30px;
    position: absolute;
    bottom: -2px;
    opacity: 0;
    left: calc(50% - 7px);
    pointer-events: none;
    content: "";
    font-size: 0;
    border: 7px solid transparent;
    border-bottom-color: #2c224f;
    border-bottom-width: 8px;
  }

  .main-menu-list.nomega:before {
    content: "";
    display: block;
    width: 0;
    border-bottom: 2px solid #403276;
    height: 30px;
    bottom: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
  }

  .main-menu-list:hover {
    color: #403276;
  }

  .main-menu-list:hover:after {
    opacity: 1;
    bottom: 0;
  }

  .main-menu-list:hover.nomega:before {
    transition: width .2s cubic-bezier(.165,.84,.44,1);
    bottom: 0;
    width: 100%;
  }

  .submenu {
    background: rgba(44,34,79,.95);
    border-top: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: fixed;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    padding: 30px 40px 20px;
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s ease-in-out,visibility .3s ease-in-out,top ease-in-out .3s;
    width: 101%;
    top: 108px;
  }

  .submenu.submenu-open {
    visibility: visible!important;
    opacity: 1;
    top: 110px;
  }

  .submenu-list {
    flex: 0 0 140px;
    max-width: 140px;
    min-width: 140px;
    border: none;
    color: #fff;
    background: transparent;
    display: block;
    font-size: 1.4rem;
    font-family: Noto Sans JP,sans-serif;
    padding: 0 10px;
  }

  .submenu-list:before {
    display: none;
  }

  .submenu-list a {
    padding: 0 0 10px;
    display: block;
    position: relative;
    height: 100%;
    transition: color .3s;
  }

  .submenu-list a:hover {
    color: rgba(255,255,255,.7);
  }

  .submenu-list a:hover span:before {
    transform: scale(1.05);
  }

  .submenu-list a span {
    overflow: hidden;
    display: block;
    max-height: 120px;
    margin-bottom: 10px;
  }

  .submenu-list a span:before {
    content: "";
    width: 120px;
    background: #2c224f;
    height: 120px;
    display: block;
    position: relative;
    z-index: -2;
    transition: transform .3s cubic-bezier(0,0,.1,1);
  }

  .submenu-list a[href*=buy01] span:before {
    image-rendering: -webkit-optimize-contrast;
    background-image: url(../img/buy/buy01.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .submenu-list a[href*=buy02] span:before {
    image-rendering: -webkit-optimize-contrast;
    background-image: url(../img/buy/buy02.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .submenu-list a[href*=buy03] span:before {
    image-rendering: -webkit-optimize-contrast;
    background-image: url(../img/buy/buy03.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .submenu-list a[href*=buy04] span:before {
    image-rendering: -webkit-optimize-contrast;
    background-image: url(../img/buy/buy04.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .submenu-list a[href*=buy05] span:before {
    image-rendering: -webkit-optimize-contrast;
    background-image: url(../img/buy/buy05.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .submenu-list a[href*=rental01] span:before {
    image-rendering: -webkit-optimize-contrast;
    background-image: url(../img/rental/rental01.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .submenu-list a[href*=rental02] span:before {
    image-rendering: -webkit-optimize-contrast;
    background-image: url(../img/rental/rental02.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .submenu-list a[href*=rental03] span:before {
    image-rendering: -webkit-optimize-contrast;
    background-image: url(../img/rental/rental03.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .submenu-list a[href*=rental04] span:before {
    image-rendering: -webkit-optimize-contrast;
    background-image: url(../img/rental/rental04.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .submenu-list a[href*=rental05] span:before {
    image-rendering: -webkit-optimize-contrast;
    background-image: url(../img/rental/rental05.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .submenu-list a[href*=rental06] span:before {
    image-rendering: -webkit-optimize-contrast;
    background-image: url(../img/rental/rental06.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .main-menu-list.menu-active {
    color: #403276;
  }

  .main-menu-list.menu-active:before {
    display: block;
    content: "";
    border-bottom: 2px solid #403276;
    border-color: #403276;
    transition: width .2s cubic-bezier(.165,.84,.44,1);
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    opacity: 1;
  }

  .main-menu-list.menu-active:hover {
    color: #403276;
  }

  .main-menu-list.menu-active:hover:before {
    border-color: #403276;
  }

  .btn-sp-contact {
    cursor: pointer;
    display: table;
    min-width: 350px;
    font-size: 1.8rem;
    padding: .65em 20px;
    transition: background .3s,color .3s;
    margin: 0;
  }

  .btn-sp-contact:before {
    right: 20px;
  }

  .btn-sp-contact:hover {
    background: #fff;
  }

  .footer-contact {
    padding: 50px 0;
  }

  .h-footer-contact {
    font-size: 2rem;
  }

  .footer-contact-tel {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .footer-contact-title {
    font-size: 2rem;
    margin-right: 16px;
    padding-bottom: 10px;
  }

  .footer-contact-telno {
    font-size: 3.6rem;
  }

  .footer-contact-address {
    margin-top: 20px;
    font-size: 1.4rem;
  }

  .footer-info {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    background: url(../img/common/footer_kamon02@2x.png) no-repeat;
    background-size: 190px;
    background-position: right 90px top 30px;
  }

  .footer-copy {
    text-align: left;
    flex: 0 0 100%;
  }

  .footer-logo {
    width: 190px;
    margin: 20px 0 5px;
    image-rendering: -webkit-optimize-contrast;
  }

  .footer-info-data {
    flex: 0 0 360px;
    margin-top: 10px;
  }

  .footer-info-tel {
    text-align: left;
    display: block;
    font-size: 2rem;
  }

  .footer-info-address {
    text-align: left;
    font-size: 1.4rem;
  }

  .copyright {
    font-size: 1.2rem;
    padding: 10px 0;
  }

  .footer-link {
    padding: 20px 0 15px;
  }

  .top-btn {
    cursor: pointer;
    width: 60px;
    height: 60px;
    right: 10px;
    bottom: 20px;
  }

  .top-btn:hover {
    opacity: .9;
  }

  .btn-fix {
    bottom: 57px;
  }

  .btn-modal-close {
    cursor: pointer;
    display: table;
    min-width: 350px;
    font-size: 1.8rem;
    padding: .65em 20px;
    transition: background .3s,color .3s;
    margin: 0;
  }

  .btn-modal-close:before {
    right: 20px;
  }

  .btn-modal-close:hover {
    background: #fff;
  }

  .index-main,
  .page-main {
    margin-top: 110px;
  }

  .contents-area {
    padding: 50px 0 0;
  }

  .sidemenu-row {
    display: flex;
    flex-wrap: wrap;
    width: 1120px;
    margin: 0 auto;
  }

  .breadcrumb {
    font-size: 1.2rem;
    padding: 9px 0;
    margin: -35px 0 0;
    height: auto;
  }

  .breadcrumb ol {
    overflow-x: auto;
    white-space: auto;
    padding: 0;
  }

  .breadcrumb a {
    transition: color .3s;
  }

  .breadcrumb a:hover {
    color: #4892cc;
  }

  .btn-default {
    cursor: pointer;
    display: table;
    min-width: 350px;
    font-size: 1.8rem;
    padding: .65em 20px;
    transition: background .3s,color .3s;
    margin: 0;
  }

  .btn-default:before {
    right: 20px;
  }

  .btn-default:hover {
    background: #534683;
  }

  .btn-default--side {
    cursor: pointer;
    display: table;
    min-width: 350px;
    font-size: 1.8rem;
    padding: .65em 20px;
    transition: background .3s,color .3s;
    margin: 0;
  }

  .btn-default--side:before {
    right: 20px;
  }

  .btn-default--side:hover {
    background: #534683;
  }

  .btn-default--side {
    min-width: 100%;
    width: 100%;
    margin-top: 10px;
  }

  .btn-default--side:before {
    right: 12px;
  }

  .btn-shop--side {
    cursor: pointer;
    display: table;
    min-width: 350px;
    font-size: 1.8rem;
    padding: .65em 20px;
    transition: background .3s,color .3s;
    margin: 0;
  }

  .btn-shop--side:before {
    right: 20px;
  }

  .btn-shop--side:hover {
    background: #8a8150;
  }

  .btn-shop--side {
    min-width: 100%;
    width: 100%;
    margin-top: 10px;
  }

  .btn-shop--side:before {
    right: 12px;
  }

  .btn-map {
    cursor: pointer;
    display: table;
    min-width: 350px;
    font-size: 1.8rem;
    padding: .65em 20px;
    transition: background .3s,color .3s;
    margin: 0;
  }

  .btn-map:before {
    right: 20px;
  }

  .btn-map:hover {
    background: #3178b0;
  }

  .btn-map {
    min-width: 110px;
    font-size: 1.4rem;
    padding: 3px 5px;
    margin: 5px 0 0;
  }

  .btn-map:hover {
    color: #fff;
  }

  .btn-return {
    cursor: pointer;
    display: table;
    min-width: 350px;
    font-size: 1.8rem;
    padding: .65em 20px;
    transition: background .3s,color .3s;
    margin: 0;
  }

  .btn-return:before {
    left: 20px;
  }

  .btn-return:hover {
    background: #403276;
  }

  .btn-return {
    margin: 70px auto 0;
  }

  .btn-return:hover {
    color: #fff;
  }

  .h-large,
  .h-information {
    font-size: 2.2rem;
    margin: 50px 0 30px;
  }

  .h-large span,
  .h-information span {
    font-size: 2rem;
    padding-bottom: 20px;
  }

  .h-large span:after,
  .h-information span:after {
    width: 50px;
    margin-top: 20px;
  }

  .h-middle {
    font-size: 3.6rem;
    border-width: 5px;
    padding-left: 16px;
    margin: 80px 0 35px;
  }

  .h-middle:after {
    bottom: -10px;
    left: -5px;
  }

  .h-small {
    font-size: 2.6rem;
    margin-bottom: 20px;
  }

  .contents-area .h-large,
  .contents-area .h-information {
    margin-top: 60px;
  }

  .h-index-pickup {
    font-size: 2.4rem;
    padding: 20px 0;
  }

  .h-index-about {
    font-size: 3rem;
    text-align: left;
    margin: 0 0 30px;
  }

  .h-index-lineup,
  .h-pege-title,
  .h-pege-title--buy {
    font-size: 3.6rem;
  }

  .h-index-lineup--small,
  .h-pege-title--small {
    font-size: 2.6rem;
  }

  .h-index-buy,
  .h-index-rent,
  .h-rent,
  .h-buy {
    height: 70px;
    font-size: 1.8rem;
  }

  .h-buy {
    height: 80px;
  }

  .h-rent {
    height: 100px;
    padding: 0;
  }

  .h-price {
    font-size: 2rem;
    margin: 30px 0 10px;
  }

  .h-price .tax {
    font-size: 1.4rem;
  }

  .h-footer-shop {
    text-align: left;
    font-size: 3.6rem;
  }

  .h-footer-shop--small {
    text-align: left;
    font-size: 2.2rem;
  }

  .footer-shop {
    position: relative;
    min-width: 1120px;
    margin-top: 80px;
    padding: 50px 0;
    z-index: -3;
  }

  .footer-shop-data {
    order: 1;
    padding: 0!important;
  }

  .footer-shop-data>div {
    position: relative;
    max-width: 760px;
    width: calc(100% + 60px);
    margin: 40px -60px 0 auto;
    padding: 60px 90px;
    background: url(../img/common/bg-pale.png);
  }

  .footer-shop-data>div:after {
    content: "";
    display: block;
    position: absolute;
    background: url(../img/index/pickup-bg.png) #c1bccf;
    width: 100%;
    left: 160px;
    bottom: -20px;
    top: 100px;
    z-index: -2;
  }

  .footer-shop-data>div:before {
    content: "";
    display: block;
    position: absolute;
    background: url(../img/index/top_kamon@2x.png);
    background-size: 100%;
    width: 200px;
    height: 200px;
    left: 20px;
    top: -65px;
    z-index: 0;
  }

  .footer-shop-data>div h3,
  .footer-shop-data>div h4,
  .footer-shop-data>div p,
  .footer-shop-data>div a {
    position: relative;
    z-index: 2;
  }

  .footer-shop-img {
    order: 2;
  }

  .footer-shop-img img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-height: 520px;
    object-position: 50% 50%;
    position: relative;
    z-index: -1;
  }

  .h-about {
    font-size: 3rem;
    margin-bottom: 30px;
  }

  .h-history {
    width: 320px;
    padding: 35px;
    font-size: 3rem;
  }

  .h-history:before {
    left: 20px;
    right: -20px;
  }

  .h-history span {
    font-size: 2rem;
  }

  .h-history span:after {
    width: 50px;
    margin-bottom: 10px;
  }

  .h-history--middle {
    text-align: left;
    font-size: 2.6rem;
    margin-bottom: 20px;
    max-width: 545px;
  }

  .h-information {
    font-size: 3rem;
    margin-bottom: 10px;
  }

  .h-shop {
    font-size: 3rem;
    text-align: left;
    margin-bottom: 20px;
  }

  .h-access {
    font-size: 2.6rem;
    margin: 30px 0 20px;
  }

  .h-access-small {
    font-size: 2.6rem;
    margin: 30px 0 20px;
  }

  .h-policy {
    font-size: 1.8rem;
    margin-top: 30px;
  }

  .h-policy:before {
    left: -10px;
  }

  .page-header {
    object-fit: cover;
    max-height: 500px;
    min-height: 500px;
  }

  .page-header img {
    width: 68%;
    min-height: 500px;
    min-width: 1090px;
    margin-left: auto;
    -webkit-mask-image: url(../img/common/mask.svg);
    mask-image: url(../img/common/mask.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: top right;
    mask-position: top right;
    -webkit-mask-size: 100%;
    mask-size: 100%;
    z-index: 3;
  }

  .page-header:after {
    content: "";
    width: 1000px;
    height: 800px;
    left: 52%;
    transform: rotate(45deg);
    transform-origin: left top;
  }

  .page-header.about {
    max-height: 300px;
    min-height: 300px;
  }

  .page-header.about img {
    object-position: center left;
    object-fit: cover;
    max-height: 300px;
    min-height: 300px;
  }

  .page-hader-data {
    padding: 0;
    width: 350px;
    position: absolute;
    left: calc(50% - 560px);
    top: 50%;
    transform: translateY(-50%);
  }

  .h-pege-title--noimage {
    font-size: 3rem;
  }

  .h-pege-title--noimage span {
    display: inline;
    font-size: 2rem;
    margin-bottom: 0;
  }

  .page-hader-txt {
    font-size: 1.4rem;
  }

  .page-header--noimage {
    background: url(../img/common/title-bg.png) no-repeat center center;
    background-size: auto;
    height: 180px;
    padding-top: 60px;
  }

  .page-header--noimage.buy {
    background-image: url(../img/common/title-bg--buy.png);
  }

  .sidemenu-row {
    display: flex;
  }

  .sidemenu-item {
    display: block;
    flex: 0 0 280px;
    max-width: 280px;
    margin-right: 40px;
    padding: 0;
    order: 1;
  }

  .sidemenu {
    width: 280px;
  }

  .sidemenu-list:not(.title) a {
    font-size: 1.4rem;
    padding: 0 16px 0 76px;
  }

  .main-column {
    flex: 0 0 800px;
    max-width: 800px;
    padding: 0;
    order: 2;
  }

  .page-mv {
    margin: 0;
  }

  .gallery,
  .slick-gallery {
    width: 600px;
    margin: 30px auto 0;
    cursor: pointer;
  }

  .gallery.buy,
  .slick-gallery.buy {
    width: 700px;
  }

  .gallery-cap {
    font-size: 1.2rem;
    margin: 5px 0 50px;
  }

  .gallery-cap+p {
    margin-top: -30px;
    margin-bottom: 50px;
  }

  .slick-gallery-nav .slick-track {
    width: 411px!important;
  }

  .slick-gallery-nav .slick-slide {
    width: 126px!important;
    margin: 0 5px;
  }

  .slick-gallery-nav .slick-slide img {
    transition: opacity .3s;
    width: 100%;
  }

  .slick-gallery-nav .slick-slide:hover img {
    cursor: pointer;
  }

  .slick-gallery-nav.rental .slick-track {
    width: 330px!important;
  }

  .slick-gallery-nav.rental .slick-slide {
    width: 100px!important;
  }

  .slick-gallery-nav.rental04 .slick-track,
  .slick-gallery-nav.rental05 .slick-track {
    width: 240px!important;
  }

  .slick-gallery-nav.rental04 .slick-slide,
  .slick-gallery-nav.rental05 .slick-slide {
    width: 110px!important;
  }

  .slick-gallery-nav.rental05 .slick-track {
    width: 360px!important;
  }

  .slick-next,
  .slick-prev {
    width: 50px;
    height: 50px;
    opacity: 0;
    transition: opacity .3s;
  }

  .slick-gallery:hover .slick-next,
  .slick-gallery:hover .slick-prev {
    opacity: 1;
  }

  .slick-next {
    background-size: 50px!important;
    right: -50px;
  }

  .slick-prev {
    background-size: 50px!important;
    left: -50px;
  }

  .buy-rent-image {
    margin: 30px auto 0;
  }

  .rent-txt {
    margin-top: 16px;
    margin-bottom: 30px;
  }

  .set-cap {
    font-size: 1.4rem;
  }

  .desc-txt {
    text-align: center;
  }

  .flow-area {
    padding: 40px;
    margin-top: 30px;
  }

  .flow-title {
    height: 220px;
    margin: -40px -40px 30px;
  }

  .flow-title:before {
    width: 400px;
    top: 40px;
    left: calc(50% - 200px);
  }

  .flow-title p {
    font-size: 1.4rem;
  }

  .flow-title.rental01 {
    background-image: url(../img/rental01/rental01_flow_bg.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top left;
    z-index: -2;
  }

  .flow-title.rental02 {
    background-image: url(../img/rental02/rental02_flow_bg.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top left;
    z-index: -2;
  }

  .flow-title.rental03 {
    background-image: url(../img/rental03/rental03_flow_bg.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top left;
    z-index: -2;
  }

  .flow-title.rental04 {
    background-image: url(../img/rental04/rental04_flow_bg.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top left;
    z-index: -2;
  }

  .h-flow-title {
    font-size: 4rem;
    margin: 40px 0 10px;
  }

  .h-flow-title span {
    font-size: 2rem;
  }

  .flow-row>div {
    display: flex;
  }

  .flow-item {
    padding: 20px 20px 30px;
    flex: 0 0 100%;
  }

  .flow-item:after {
    content: "";
    font-size: 0;
    border: 15px solid transparent;
    border-left-color: #ecb360;
    border-left-width: 16px;
    right: -31px;
    position: absolute;
    top: 50%;
    margin-top: -15px;
  }

  .flow-item.last:after {
    display: none;
  }

  .flow-item img {
    width: auto;
    height: 220px;
    margin: 16px auto 20px;
  }

  .flow-item p {
    font-size: 1.4rem;
  }

  .h-flow {
    height: 40px;
    padding-left: 48px;
    font-size: 2.2rem;
  }

  .flow-no1:before {
    width: 40px;
    height: 40px;
  }

  .flow-no2:before {
    width: 40px;
    height: 40px;
  }

  .flow-no3:before {
    width: 40px;
    height: 40px;
  }

  .flow-no4:before {
    width: 40px;
    height: 40px;
  }

  .flow-no5:before {
    width: 40px;
    height: 40px;
  }

  .flow-no6:before {
    width: 40px;
    height: 40px;
  }

  .flow-no7:before {
    width: 40px;
    height: 40px;
  }

  .flow-2col {
    display: flex;
    align-items: center;
  }

  .flow-2col img {
    flex: 0 0 220px;
    margin-left: 40px;
    margin-right: 40px;
  }

  .index-mv {
    min-width: 1120px;
  }

  .index-about {
    margin-top: 60px;
  }

  .index-about-row {
    min-width: 1120px;
  }

  .index-about-img {
    max-height: 500px;
    order: 2;
  }

  .index-about-img img {
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
  }

  .index-about-data {
    background-image: url(../img/index/top_kamon@2x.png),url(../img/common/bg-pale.png);
    background-repeat: no-repeat,repeat;
    background-size: 200px,auto;
    background-position: right 480px top 20px,left top;
    padding: 100px 0;
    order: 1;
  }

  .index-about-data>div {
    max-width: 560px;
    margin: 0 0 0 auto;
  }

  .index-about-txt {
    padding-right: 80px;
    margin-bottom: 40px;
  }

  .inex-lineup {
    margin-top: 60px;
  }

  .index-lineup-row {
    max-width: 1600px;
    min-width: 1120px;
    margin: auto;
    max-height: 600px;
    min-height: 480px;
    height: calc(50vw*.75);
    position: relative;
  }

  .index-lineup-data {
    max-width: 740px!important;
    margin: 60px 0 0;
    padding: 80px!important;
    background-size: 140px,75px,auto;
    background-position: right 110px top 20px,left top,left top;
    position: absolute;
    left: calc(50% - 40px);
    width: calc(50% + 40px);
  }

  .index-lineup-data:after {
    right: -9999px;
    bottom: -20px;
    top: 280px;
    left: 16px;
    z-index: -1;
  }

  .index-lineup-img img {
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: calc(50vw*.75);
    min-height: 420px;
    max-height: 600px;
    object-position: 50% 50%;
  }

  .index-lineup-txt {
    max-width: 510px;
  }

  .index-rent-item {
    margin-top: 30px;
  }

  p.index-buy-txt,
  p.buy-txt {
    font-size: 1.4rem;
  }

  .index-lineup-data.rent {
    min-width: 560px;
    order: 1;
    position: absolute;
    left: auto;
    right: calc(50% - 40px);
    margin-left: 50%;
  }

  .index-lineup-data.rent:after {
    right: 0;
    left: -9999px;
  }

  .index-lineup-img.rent {
    order: 2;
    z-index: -1;
    margin: 0;
    position: absolute;
    right: 0;
  }

  p.buy-txt {
    font-size: 1.4rem;
  }

  .buy-item,
  .rent-item {
    margin-top: 0;
  }

  .rental-men-item {
    margin-top: 20px;
  }

  .set-item {
    padding: 20px;
    margin-top: 50px;
  }

  .set-item>div {
    padding: 30px;
  }

  .set-item img {
    margin: 20px auto 0;
  }

  .set-item.min {
    width: 550px;
    margin-left: auto;
    margin-right: auto;
  }

  .h-set {
    font-size: 2.6rem;
  }

  .set-txt {
    font-size: 1.4rem;
  }

  .set-flex {
    align-items: flex-start;
  }

  .set-item .set-flex {
    padding: 40px;
  }

  .set-flex .h-set {
    margin: 20px 0;
  }

  .set-flex>div {
    flex: 0 0 auto;
    max-width: 405px;
  }

  .set-flex img {
    flex-basis: auto;
  }

  .set-flex--buy04 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .set-flex--buy04 .h-set {
    text-align: left;
    margin: 20px 0;
  }

  .set-flex--buy04 .set-txt {
    text-align: left;
  }

  .set-item .set-flex--buy04 {
    padding: 40px;
  }

  .set-flex--buy04 img {
    width: auto;
    margin: 0;
  }

  .about-image {
    width: 960px;
    margin: 50px auto 60px;
  }

  .history-title {
    background-image: url(../img/about/history-bg_pc.jpg);
    height: 450px;
  }

  .history-txt {
    max-width: 540px;
    margin-bottom: 10px;
  }

  .history-name {
    max-width: 530px;
    margin-top: 20px;
  }

  .history-cap {
    font-size: 1.4rem;
    margin-top: 8px;
  }

  .history-item {
    margin-top: 40px;
  }

  .history-item:nth-of-type(1) p,
  .history-item:nth-of-type(1) h3 {
    margin-left: auto;
  }

  .info-dl dt,
  .info-dl dd {
    margin-bottom: 16px;
  }

  .info-row {
    display: flex;
    justify-content: space-between;
    padding: 30px 10px;
    padding-right: 0;
  }

  .info-img {
    flex: 0 0 400px;
    max-width: 400px;
    margin: 0;
    align-self: flex-start;
  }

  .shop-mv {
    margin-top: 30px;
  }

  .shop-row {
    display: flex;
    justify-content: space-between;
    margin: 30px auto 60px;
  }

  .shop-row img {
    flex: 0 0 450px;
    width: 450px;
  }

  .shop-address {
    text-align: left;
  }

  .access-img {
    padding: 0;
    display: flex;
    margin-top: 20px;
  }

  .access-img img {
    flex: 0 0 50%;
  }

  .map {
    width: 700px;
    margin: auto;
  }

  .access-data {
    width: 700px;
    margin: 40px auto 30px;
    padding: 10px 30px 30px;
  }

  .access-data h4 {
    font-size: 1.8rem;
  }

  .access-data .parking {
    padding-left: 28px;
  }

  .access-data .parking:before {
    padding: 0 7px;
  }

  .access-data li {
    padding-left: 45px;
  }

  .access-data li:before {
    left: 28px;
  }

  .access-data p {
    font-size: 1.4rem;
  }

  .p-list-row {
    display: flex;
    margin-top: 5px;
  }

  p.p-note {
    padding-left: 28px;
    margin-top: 5px;
    font-size: 1.2rem;
  }

  .access-data-flex {
    display: flex;
  }

  .access-data-flex div {
    margin-right: 40px;
  }

  .u-txt-s {
    font-size: 1.4rem;
  }

  .pc-none {
    display: none;
  }

  .u-mt0 {
    margin-top: 0!important;
  }

  .u-mb0 {
    margin-bottom: 0!important;
  }

  .u-ml0 {
    margin-left: 0!important;
  }

  .u-mr0 {
    margin-right: 0!important;
  }

  .u-pt0 {
    padding-top: 0!important;
  }

  .u-pb0 {
    padding-bottom: 0!important;
  }

  .u-pl0 {
    padding-left: 0!important;
  }

  .u-pr0 {
    padding-right: 0!important;
  }

  .u-mt5 {
    margin-top: 5px!important;
  }

  .u-mb5 {
    margin-bottom: 5px!important;
  }

  .u-ml5 {
    margin-left: 5px!important;
  }

  .u-mr5 {
    margin-right: 5px!important;
  }

  .u-pt5 {
    padding-top: 5px!important;
  }

  .u-pb5 {
    padding-bottom: 5px!important;
  }

  .u-pl5 {
    padding-left: 5px!important;
  }

  .u-pr5 {
    padding-right: 5px!important;
  }

  .u-mt10 {
    margin-top: 10px!important;
  }

  .u-mb10 {
    margin-bottom: 10px!important;
  }

  .u-ml10 {
    margin-left: 10px!important;
  }

  .u-mr10 {
    margin-right: 10px!important;
  }

  .u-pt10 {
    padding-top: 10px!important;
  }

  .u-pb10 {
    padding-bottom: 10px!important;
  }

  .u-pl10 {
    padding-left: 10px!important;
  }

  .u-pr10 {
    padding-right: 10px!important;
  }

  .u-mt15 {
    margin-top: 15px!important;
  }

  .u-mb15 {
    margin-bottom: 15px!important;
  }

  .u-ml15 {
    margin-left: 15px!important;
  }

  .u-mr15 {
    margin-right: 15px!important;
  }

  .u-pt15 {
    padding-top: 15px!important;
  }

  .u-pb15 {
    padding-bottom: 15px!important;
  }

  .u-pl15 {
    padding-left: 15px!important;
  }

  .u-pr15 {
    padding-right: 15px!important;
  }

  .u-mt20 {
    margin-top: 20px!important;
  }

  .u-mb20 {
    margin-bottom: 20px!important;
  }

  .u-ml20 {
    margin-left: 20px!important;
  }

  .u-mr20 {
    margin-right: 20px!important;
  }

  .u-pt20 {
    padding-top: 20px!important;
  }

  .u-pb20 {
    padding-bottom: 20px!important;
  }

  .u-pl20 {
    padding-left: 20px!important;
  }

  .u-pr20 {
    padding-right: 20px!important;
  }

  .u-mt25 {
    margin-top: 25px!important;
  }

  .u-mb25 {
    margin-bottom: 25px!important;
  }

  .u-ml25 {
    margin-left: 25px!important;
  }

  .u-mr25 {
    margin-right: 25px!important;
  }

  .u-pt25 {
    padding-top: 25px!important;
  }

  .u-pb25 {
    padding-bottom: 25px!important;
  }

  .u-pl25 {
    padding-left: 25px!important;
  }

  .u-pr25 {
    padding-right: 25px!important;
  }

  .u-mt30 {
    margin-top: 30px!important;
  }

  .u-mb30 {
    margin-bottom: 30px!important;
  }

  .u-ml30 {
    margin-left: 30px!important;
  }

  .u-mr30 {
    margin-right: 30px!important;
  }

  .u-pt30 {
    padding-top: 30px!important;
  }

  .u-pb30 {
    padding-bottom: 30px!important;
  }

  .u-pl30 {
    padding-left: 30px!important;
  }

  .u-pr30 {
    padding-right: 30px!important;
  }

  .u-mt35 {
    margin-top: 35px!important;
  }

  .u-mb35 {
    margin-bottom: 35px!important;
  }

  .u-ml35 {
    margin-left: 35px!important;
  }

  .u-mr35 {
    margin-right: 35px!important;
  }

  .u-pt35 {
    padding-top: 35px!important;
  }

  .u-pb35 {
    padding-bottom: 35px!important;
  }

  .u-pl35 {
    padding-left: 35px!important;
  }

  .u-pr35 {
    padding-right: 35px!important;
  }

  .u-mt40 {
    margin-top: 40px!important;
  }

  .u-mb40 {
    margin-bottom: 40px!important;
  }

  .u-ml40 {
    margin-left: 40px!important;
  }

  .u-mr40 {
    margin-right: 40px!important;
  }

  .u-pt40 {
    padding-top: 40px!important;
  }

  .u-pb40 {
    padding-bottom: 40px!important;
  }

  .u-pl40 {
    padding-left: 40px!important;
  }

  .u-pr40 {
    padding-right: 40px!important;
  }

  .u-mt45 {
    margin-top: 45px!important;
  }

  .u-mb45 {
    margin-bottom: 45px!important;
  }

  .u-ml45 {
    margin-left: 45px!important;
  }

  .u-mr45 {
    margin-right: 45px!important;
  }

  .u-pt45 {
    padding-top: 45px!important;
  }

  .u-pb45 {
    padding-bottom: 45px!important;
  }

  .u-pl45 {
    padding-left: 45px!important;
  }

  .u-pr45 {
    padding-right: 45px!important;
  }

  .u-mt50 {
    margin-top: 50px!important;
  }

  .u-mb50 {
    margin-bottom: 50px!important;
  }

  .u-ml50 {
    margin-left: 50px!important;
  }

  .u-mr50 {
    margin-right: 50px!important;
  }

  .u-pt50 {
    padding-top: 50px!important;
  }

  .u-pb50 {
    padding-bottom: 50px!important;
  }

  .u-pl50 {
    padding-left: 50px!important;
  }

  .u-pr50 {
    padding-right: 50px!important;
  }

  .u-mt55 {
    margin-top: 55px!important;
  }

  .u-mb55 {
    margin-bottom: 55px!important;
  }

  .u-ml55 {
    margin-left: 55px!important;
  }

  .u-mr55 {
    margin-right: 55px!important;
  }

  .u-pt55 {
    padding-top: 55px!important;
  }

  .u-pb55 {
    padding-bottom: 55px!important;
  }

  .u-pl55 {
    padding-left: 55px!important;
  }

  .u-pr55 {
    padding-right: 55px!important;
  }

  .u-mt60 {
    margin-top: 60px!important;
  }

  .u-mb60 {
    margin-bottom: 60px!important;
  }

  .u-ml60 {
    margin-left: 60px!important;
  }

  .u-mr60 {
    margin-right: 60px!important;
  }

  .u-pt60 {
    padding-top: 60px!important;
  }

  .u-pb60 {
    padding-bottom: 60px!important;
  }

  .u-pl60 {
    padding-left: 60px!important;
  }

  .u-pr60 {
    padding-right: 60px!important;
  }

  .u-mt65 {
    margin-top: 65px!important;
  }

  .u-mb65 {
    margin-bottom: 65px!important;
  }

  .u-ml65 {
    margin-left: 65px!important;
  }

  .u-mr65 {
    margin-right: 65px!important;
  }

  .u-pt65 {
    padding-top: 65px!important;
  }

  .u-pb65 {
    padding-bottom: 65px!important;
  }

  .u-pl65 {
    padding-left: 65px!important;
  }

  .u-pr65 {
    padding-right: 65px!important;
  }

  .u-mt70 {
    margin-top: 70px!important;
  }

  .u-mb70 {
    margin-bottom: 70px!important;
  }

  .u-ml70 {
    margin-left: 70px!important;
  }

  .u-mr70 {
    margin-right: 70px!important;
  }

  .u-pt70 {
    padding-top: 70px!important;
  }

  .u-pb70 {
    padding-bottom: 70px!important;
  }

  .u-pl70 {
    padding-left: 70px!important;
  }

  .u-pr70 {
    padding-right: 70px!important;
  }

  .u-mt75 {
    margin-top: 75px!important;
  }

  .u-mb75 {
    margin-bottom: 75px!important;
  }

  .u-ml75 {
    margin-left: 75px!important;
  }

  .u-mr75 {
    margin-right: 75px!important;
  }

  .u-pt75 {
    padding-top: 75px!important;
  }

  .u-pb75 {
    padding-bottom: 75px!important;
  }

  .u-pl75 {
    padding-left: 75px!important;
  }

  .u-pr75 {
    padding-right: 75px!important;
  }

  .u-mt80 {
    margin-top: 80px!important;
  }

  .u-mb80 {
    margin-bottom: 80px!important;
  }

  .u-ml80 {
    margin-left: 80px!important;
  }

  .u-mr80 {
    margin-right: 80px!important;
  }

  .u-pt80 {
    padding-top: 80px!important;
  }

  .u-pb80 {
    padding-bottom: 80px!important;
  }

  .u-pl80 {
    padding-left: 80px!important;
  }

  .u-pr80 {
    padding-right: 80px!important;
  }

  .u-mt85 {
    margin-top: 85px!important;
  }

  .u-mb85 {
    margin-bottom: 85px!important;
  }

  .u-ml85 {
    margin-left: 85px!important;
  }

  .u-mr85 {
    margin-right: 85px!important;
  }

  .u-pt85 {
    padding-top: 85px!important;
  }

  .u-pb85 {
    padding-bottom: 85px!important;
  }

  .u-pl85 {
    padding-left: 85px!important;
  }

  .u-pr85 {
    padding-right: 85px!important;
  }

  .u-mt90 {
    margin-top: 90px!important;
  }

  .u-mb90 {
    margin-bottom: 90px!important;
  }

  .u-ml90 {
    margin-left: 90px!important;
  }

  .u-mr90 {
    margin-right: 90px!important;
  }

  .u-pt90 {
    padding-top: 90px!important;
  }

  .u-pb90 {
    padding-bottom: 90px!important;
  }

  .u-pl90 {
    padding-left: 90px!important;
  }

  .u-pr90 {
    padding-right: 90px!important;
  }

  .u-mt95 {
    margin-top: 95px!important;
  }

  .u-mb95 {
    margin-bottom: 95px!important;
  }

  .u-ml95 {
    margin-left: 95px!important;
  }

  .u-mr95 {
    margin-right: 95px!important;
  }

  .u-pt95 {
    padding-top: 95px!important;
  }

  .u-pb95 {
    padding-bottom: 95px!important;
  }

  .u-pl95 {
    padding-left: 95px!important;
  }

  .u-pr95 {
    padding-right: 95px!important;
  }

  .u-mt100 {
    margin-top: 100px!important;
  }

  .u-mb100 {
    margin-bottom: 100px!important;
  }

  .u-ml100 {
    margin-left: 100px!important;
  }

  .u-mr100 {
    margin-right: 100px!important;
  }

  .u-pt100 {
    padding-top: 100px!important;
  }

  .u-pb100 {
    padding-bottom: 100px!important;
  }

  .u-pl100 {
    padding-left: 100px!important;
  }

  .u-pr100 {
    padding-right: 100px!important;
  }

  .anime-opa {
    transition: opacity .5s;
  }

  .anime-opa:hover {
    opacity: .8;
  }

  .anime-opaimg {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: opacity .5s cubic-bezier(0,0,.1,1);
  }

  .anime-opaimg img,
  .anime-opaimg video {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: opacity .5s cubic-bezier(0,0,.1,1),transform .5s cubic-bezier(0,0,.1,1);
  }

  .anime-opaimg:hover {
    opacity: .8!important;
  }

  .anime-opaimg:hover img,
  .anime-opaimg:hover video {
    opacity: .8!important;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: scale(1.05);
  }

  .anime-img {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .anime-img img,
  .anime-img video {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .5s cubic-bezier(0,0,.1,1);
  }

  .anime-img:hover img,
  .anime-img:hover video {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: scale(1.05);
  }

  .js_opaimg {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: relative;
  }

  .js_opaimg img,
  .js_opaimg video {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: scale(1.05);
  }

  .anime-ponpon {
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .anime-ponpon:hover,
  .anime-ponpon:focus,
  .anime-ponpon:active {
    animation: anime-ponpon .85s ease-in-out 1;
  }
